import * as rax from 'retry-axios'
import axios from 'axios'
import moment from 'moment-timezone'
import { saveAs } from 'file-saver'
import { auth, envelopes } from '@verified/libvf'
import "./dashboard.scss"

const configKeys = {
    columns : [
      { key: "documents.0.template.userData.source", type:"standard", fixed:true},
      { key: "documents.0.template.userData.firstName", type:"standard", fixed:true},
      { key: "documents.0.template.userData.lastName", type:"standard", fixed:true},
      { key: "documents.0.template.userData.email", type:"standard", fixed:true},
      { key: "documents.0.signatures.0.personalNumber", type:"standard", fixed:true},
      { key: "documents.0.template.userData.ssn", type:"standard", fixed:true},
      { key: "documents.0.template.userData.ownedBy.private", type:"standard", fixed:true},
      { key: "documents.0.template.userData.ownedBy.other", type:"standard", fixed:true},
      { key: "documents.0.template.userData.ownedBy.multipleOwners", type:"standard", fixed:true},
      { key: "documents.0.template.userData.shareHolderName", type:"standard", fixed:true},
      { key: "documents.0.template.userData.shareHolderNo", type:"standard", fixed:true},
      { key: "documents.0.template.userData.question", type:"question"},
      { key: "documents.0.template.userData.voteType.voteOp1", type:"standard", fixed:true},
      { key: "documents.0.template.userData.voteType.voteOp2", type:"standard", fixed:true},
      { key: "documents.0.template.userData.sec3Info1", type:"standard", fixed:true},
      { key: "documents.0.template.userData.sec3Info2", type:"standard", fixed:true},
      { key: "documents.0.template.userData.boardMembers", type:"standard", fixed:true},
      { key: "documents.0.signatures.0.status", type:"standard"},
      { key: "documents.0.signatures.0.date", type:"standard"},
      { key: "recipients.0.signingMethod", type:"standard"},
      { key: "documents.0.signatures.0.ip", type:"standard"},
      { key: "id", type:"standard"},
    ],

}
const colNames = {
  boardMembers : "bisnodeName"
}
let questionHeaders = []

export default {
    name: "ve-dashboard",
    props: {
        sheetname: {
            type: String
        },
        config: {
            type: Object,
            default: function () { return {} }
        },
        tags:{
            type: Array,
            default: function(){ return [] }
        }
    },
    data() {
        return {
            opacity: 0,
            step: 1,
            loading: false,
            fetching: false,
            translations: null,
            exceptions: [],
            links: [],
            selectedTag: null,
            envelopeCount: null,
            fetchedCount: 0,
            content: {
                hidden: false,
                disabled: false
            },
            user: {
                companies: null,
                company: null,
            },
            csv: null,
            columns: [
                { key: "documents.0.template.userData.source", type:"standard", fixed:true},
                { key: "documents.0.template.userData.firstName", type:"standard", fixed:true},
                { key: "documents.0.template.userData.lastName", type:"standard", fixed:true},
                { key: "documents.0.template.userData.email", type:"standard", fixed:true},
                { key: "documents.0.signatures.0.personalNumber", type:"standard", fixed:true},
                { key: "documents.0.template.userData.ssn", type:"standard", fixed:true},
                { key: "documents.0.template.userData.ownedBy.private", type:"standard", fixed:true},
                { key: "documents.0.template.userData.ownedBy.other", type:"standard", fixed:true},
                { key: "documents.0.template.userData.ownedBy.multipleOwners", type:"standard", fixed:true},
                { key: "documents.0.template.userData.shareHolderName", type:"standard", fixed:true},
                { key: "documents.0.template.userData.shareHolderNo", type:"standard", fixed:true},
                { key: "documents.0.template.userData.question", type:"question"},
                { key: "documents.0.signatures.0.status", type:"standard"},
                { key: "documents.0.signatures.0.date", type:"standard"},
                { key: "recipients.0.signingMethod", type:"standard"},
                { key: "documents.0.signatures.0.ip", type:"standard"},
                { key: "id", type:"standard"},
            ],
            questionHeaders: []
        }
    },
    computed: {
        companyNames() {
            console.log("companyNames", this.user.companies)
            if (this.user.companies) {
                return this.user.companies.map((el) => { return { text: el.name, value: el.id } })
            }
            return []
        }
    },
    watch: {
        exceptions: function (newVal) {
            if (newVal.length)
                this.$emit('exception', newVal[newVal.length - 1])
        },
        // "user.company" : function(newVal, oldVal){
        //     this.getTags()
        // }

    },
    methods: {
        // Return config label for given key and language, or value if no label
        __config(key) {
            if (this.config[key])
                if (this.config[key][this.$root.locale])
                    return this.config[key][this.$root.locale]
                else
                    return this.config[key].value
        },
        openUrl(url) { window.open(url) },
        login(user) {
            console.log("login", user)
            Object.assign(this.user, user)
            setTimeout(() => {
                this.opacity = 1
            }, 10)
            this.$emit("user", this.user)
            //
            this.getCompanies().then((companies) => {
                this.updateUser(companies)
            })
            // this.updateUser([{uid:"/companies/S1ZZMKsqv"}])
        },
        logout() {
            localStorage.removeItem("user")
            location.reload()
        },
        exception(e) {
            this.exceptions.push(e)
        },


        langTo(lang) {
            this.$root.locale = lang
            this.$forceUpdate()
            this.$emit("language", this.$root.locale)
        },
        selectTab(tab) {
            this.selectedTab = tab
        },
        updateUser(companies) {
            console.log("updateUser", companies)
            this.user.companies = companies
            if (companies.length == 1) {
                this.companySelected(companies[0])
            }
        },
        companySelected(company) {
            console.log("company", company)
            this.user.company = company
            auth.setNamespace(company.uid)
            // auth.setNamespace("/companies/S1ZZMKsqv")
            // this.tags = this.__config("tags").split("\n")
        },
        tagSelected(tag) {
            console.log("tag", tag)
            this.selectedTag = tag
            this.getEnvelopes()
        },
        getSelectedCompanyIndex() {
            if (this.user.company) {
                return this.user.companies.indexOf(this.user.company)
            }
            return -1
        },
        getEnvelopeData(field, envelope){
            return field.split(".").reduce(
                ((s,k) => {
                    return s ? s[k] : null
                }),
                envelope)
        },
        async getCompanies() {
            this.loading = true
            let self = this
            console.log("getCompanies");
            let companies = []
            let promises = []
            let res = await axios({
                url: `/api/auth?action=OPTIONS&resource=%2Fcompanies`,
                method: "GET",
                headers: {
                    'authorization': `JWT ${auth.token}`,
                    'x-namespace': auth.namespace
                },
                withCredentials: "same-origin"
            });

            for (let resource of res.data.resources) {

                let promise = axios({
                    url: `/api${resource}`,
                    method: "GET",
                    headers: {
                        'authorization': `JWT ${auth.token}`,
                        'x-namespace': auth.namespace
                    },
                    withCredentials: "same-origin"
                }).then((c) => {
                    c.data.text = c.data.name
                    companies.push(c.data)
                })

                promises.push(promise);

            }
            return Promise.all(promises).then(() => { self.loading = false; return companies })
        },
        async getTags() {
            this.loading = true
            let self = this
            let tags = []
            this.loading = false

        },
        async getEnvelopes() {
            this.fetching = true
            this.fetchedCount = 0
            this.envelopeCount = 0
            let self = this
            let tags = []
            let max = 200
            let filters = {
                "and": [
                    { 
                        "field": "documents.id", 
                        "op": "exists", 
                        "value": true 
                    }, 
                    { 
                        "field": "trashed", 
                        "op": "ne", 
                        "value": true 
                    }, 
                    { 
                        "field": "aborted", 
                        "op": "ne", 
                        "value": true 
                    }, 
                    { 
                        "field": "documents.signed", 
                        "op": "eq", 
                        "value": true 
                    }
                ]
            }
            filters.and.push({
                "field": "documents.tags",
                "op": "eq",
                "value": `${this.selectedTag}`
            })

            const SORT = { "modified" : -1 }

            let res = await axios({

                url: `/api/query-new/envelopes?limit=1&filters=${encodeURI(JSON.stringify(filters))}`,
                method: "GET",
                headers: {
                    'authorization': `JWT ${auth.token}`,
                    'x-namespace': auth.namespace
                },
                withCredentials: "same-origin"
            });
            let totalCount = parseInt(res.headers['x-total-item-count'])
            this.envelopeCount = totalCount
            let promises = []
            let envs = []
            for(let i = 0; i <= totalCount; i += 50) {
                promises.push(axios({
                    url: `/api/query-new/envelopes?filters=${encodeURIComponent(JSON.stringify(filters))}&limit=50&from=${i}&sort=${encodeURIComponent(JSON.stringify(SORT))}`,
                    method: "GET",
                    headers: {
                        'authorization': `JWT ${auth.token}`,
                        'x-namespace': auth.namespace
                    },
                    withCredentials: "same-origin"
                }).then((queryRes) => {
                    for(let envelope of queryRes.data){
                        envs.push(envelope)
                    }
                }))
            }
            const interceptorId = rax.attach()
            let envelopeRes = await Promise.all(promises)
            promises = []
            let fetchedEnvelopes = []
            console.log(`${envs.length} envelopes `)
            envs.forEach(env => {
                promises.push(axios({
                    url: `/api${env.uid}`,
                    method: "GET",
                    headers: {
                        'authorization': `JWT ${auth.token}`,
                        'x-namespace': auth.namespace
                    },
                    withCredentials: "same-origin",
                    raxConfig:{
                        statusCodesToRetry: [[500, 599]],
                        onRetryAttempt: err => {
                            const cfg = rax.getConfig(err);
                            console.log(`Retry attempt #${cfg.currentRetryAttempt}`);
                        }
                    }
                }).then((envRes) => {
                    fetchedEnvelopes.push(envRes.data)
                    self.fetchedCount++
                // }).catch((ex)=>{
                //     console.warn(ex)
                }))
            });
            console.log(`${promises.length} promises`)
            let fetchRes = await Promise.all(promises)
            fetchedEnvelopes.sort((first, second) => {
               let firstDate = this.getEnvelopeData("documents.0.signatures.0.date", first) 
               let secondDate = this.getEnvelopeData("documents.0.signatures.0.date", second) 
              if(firstDate && secondDate){
                  return moment(firstDate).isBefore(moment(secondDate)) ? -1 : moment(firstDate).isAfter(moment(secondDate)) ? 1 : 0
              }
              return 0
            })
              fetchedEnvelopes.sort((first, second) => {
                  let firstVoteOp1 = this.getEnvelopeData("documents.0.template.userData.voteType.voteOp1", first)
                  let secondVoteOp1 = this.getEnvelopeData("documents.0.template.userData.voteType.voteOp1", second)
                  let firstVoteOp2 = this.getEnvelopeData("documents.0.template.userData.voteType.voteOp2", first)
                  let secondVoteOp2 = this.getEnvelopeData("documents.0.template.userData.voteType.voteOp2", second)
                  if(firstVoteOp1!== undefined && secondVoteOp1!== undefined){
                      return (!firstVoteOp2&&!firstVoteOp1)? -1 :(!secondVoteOp2&&!secondVoteOp1)? 1 : firstVoteOp1?-1:0
                  }
                  return 0
              })
              fetchedEnvelopes.sort((first, second) => {
                let firstDate = this.getEnvelopeData("documents.0.signatures.0.date", first) 
                let secondDate = this.getEnvelopeData("documents.0.signatures.0.date", second) 
                let firstVoteOp1 = this.getEnvelopeData("documents.0.template.userData.voteType.voteOp1", first)
                let secondVoteOp1 = this.getEnvelopeData("documents.0.template.userData.voteType.voteOp1", second)
                let firstVoteOp2 = this.getEnvelopeData("documents.0.template.userData.voteType.voteOp2", first)
                let secondVoteOp2 = this.getEnvelopeData("documents.0.template.userData.voteType.voteOp2", second)
                  if(firstVoteOp1!== undefined && secondVoteOp1!== undefined && firstDate && secondDate){
                      return firstVoteOp1===secondVoteOp1&&firstVoteOp2===secondVoteOp2?(moment(firstDate)-moment(secondDate)):0 
                  }
                  return 0
              })
            if (envs.length>max) {
                this.csv = ""
                let getCount = Math.ceil(envs.length/max);
                console.log(getCount)
                for (let i = 0; i < getCount; i++) {
                    let array1 = fetchedEnvelopes.splice(0,i!=getCount-1?max:envs.length)
                   let res =  await this.createCSV(array1)
                        console.log("Got response from lambda=",i)
                        if (i!=0) {
                            var lines = res.split('\n');
                            lines.splice(0,1);
                            var newtext = lines.join('\n');
                            res = newtext
                        }
                        this.csv += res
                }
                this.fetching = false

            }else{
               let res =  this.createCSV(fetchedEnvelopes)
                    console.log("Got response from lambda")
                    this.csv = res
                    this.fetching = false
            }
            
        },
        addQuestionHeader(questionObj, descriptionData){
            let keys = Object.keys(questionObj)
            let lang = "sv_SE"
          
            if(keys.length < 1 ){
                console.warn("No translation key found")
                return
            }
            if(keys.length > 1 ){
                console.warn("Multiple translation keys found")
            }
            if(!keys.includes(lang)){
                lang = keys[0]
            }
            let vals = questionObj[lang]
            if(vals){
                let lastQNo = ""
                //TODO: Error handling
                vals.forEach((val, i) =>{
                    let qNo;
                    let systemNo = `question${i+1}Ans`
                    if(val.ques.trim() === ""){
                        //Take from descriotion
                        let desc = descriptionData[lang][i]
                        qNo = `${lastQNo} ${desc.split(/\s\p{Lu}/u)[0]}`
                    }else{
                        qNo = val.ques.split(/\s\p{Lu}/u)[0]
                        lastQNo = qNo
                    }
          
                    let exists = questionHeaders.some((el) => el.system === systemNo && el.actual === qNo )
                    if(!exists){
                        questionHeaders.push({
                            system: systemNo,
                            actual: qNo,
                            index: i
                        })
                    }
          
                })
          
            }else{
                console.warn("Cannot find the questions")
            }
          },
        createCSV(envelopes){
            let rows = []
            let csv = null
            envelopes.forEach(envelope => {
                try{
                    let translations = this.getEnvelopeData("documents.0.template.userData.translations", envelope)
                    let columnData = configKeys.columns.reduce((acc, el) =>{
                        let extracted = this.getEnvelopeData(el.key, envelope);
                        if(extracted === null){
                          if(!/\.shareHolderName/.test(el.key)&&!/\.shareHolderNo/.test(el.key)){
                            return acc
                          }
                        }
                        if(el.type === "question"&&extracted){
                            //Special case - extract the value
                            let keys = Object.keys(extracted)
                            if(keys.length < 1 ){
                                console.warn("No translation key found")
                                return acc
                            }
                            if(keys.length > 1 ){
                                console.warn("Multiple translation keys found")
                            }
                            let descriptionData = this.getEnvelopeData("documents.0.template.userData.descriptionData", envelope)
                            this.addQuestionHeader(extracted, descriptionData)
                            let key = this.getEnvelopeData("documents.0.template.userData.lang", envelope)
                            console.log("Key", key)
                            let valsUserLang = extracted[key]
                            valsUserLang.forEach((val, i) =>{
                                let questionKey = questionHeaders[i].system
                                if(val.isText){
                                    acc[questionKey] =  val.ans
                                }else{
                                    let selectedVal = val.ans ? Object.keys(val.ans).find((e)=> val.ans[e]) : null
                                    if(translations[selectedVal]){
                                        if(translations[selectedVal]["sv_SE"]){
                                            acc[questionKey] =  translations[selectedVal]["sv_SE"]
                                        }else{
                                            acc[questionKey] =  translations[selectedVal][key]
                                        }
                                    }else{
                                        acc[questionKey] =  ""
                                    }
                                }
                            })
                        }else{
                          // console.log("if testttttt",el.key)
                            if(/\.date$/.test(el.key)){
                                extracted = moment(extracted).tz('Europe/Stockholm').format("YYYY-MM-DD HH:mm:ss")
                            }
                            if(/\.ssn$/.test(el.key)&&extracted){
                                extracted = extracted.replace(/[-/]/g, ".")
                            }
                            if (/\.boardMembers/.test(el.key)&&extracted) {
                              let strings = extracted.split(/,| /);
                              extracted = strings[(strings.length - 1)]+' '+strings[0]
                            }
                            if(/\.shareHolderNo/.test(el.key)){
                              if (this.getEnvelopeData('documents.0.template.userData.orgNr', envelope)!=null) {
                                extracted = this.getEnvelopeData('documents.0.template.userData.orgNr', envelope).replace(/[-/]/g, "");
                              }
                            }
                            if(/\.shareHolderName/.test(el.key)){
                              if (this.getEnvelopeData('documents.0.template.userData.companyName', envelope)!=null) {
                                extracted = this.getEnvelopeData('documents.0.template.userData.companyName', envelope);
                              }
                            }
                            if(/\.other/.test(el.key)){
                              if (this.getEnvelopeData('documents.0.template.userData.ownedBy.otherlegel', envelope)==true) {
                                extracted = this.getEnvelopeData('documents.0.template.userData.ownedBy.otherlegel', envelope);
                              }
                            }
                            acc[el.key] = extracted
                        }
                        return acc
                    }, {})
                    rows.push(columnData)
                }catch(e){
                    console.log("error", e, envelope)
                }
            });
            //Find columns that are present in at least one envelope
            let notEmptyCols = []
            rows.forEach((row) => {
                configKeys.columns.forEach((col) => {
                    if(row[col.key] !== undefined && !notEmptyCols.includes(col.key)){
                        notEmptyCols.push(col.key)
                    }
                })
            })
          
            let header = []
            let secondHeader = []
            let rowIndex = 0
            //Sort on signed date
            rows.sort((first, second) => {
                let dateKey = "documents.0.signatures.0.date"
                if(first[dateKey] && second[dateKey]){
                    return moment(first[dateKey]).isBefore(moment(second[dateKey])) ? -1 : moment(first[dateKey]).isAfter(moment(second[dateKey])) ? 1 : 0
                }
                return 0
            })
            //Sort on voteOp1 & voteOp2
            rows.sort((first, second) => {
                let voteTypeKey1 = "documents.0.template.userData.voteType.voteOp1"
                let voteTypeKey2 = "documents.0.template.userData.voteType.voteOp2"
                if(first[voteTypeKey1]!== undefined && second[voteTypeKey1]!== undefined){
                    return (!first[voteTypeKey2]&&!first[voteTypeKey1])? -1 :(!second[voteTypeKey2]&&!second[voteTypeKey1])? 1 : first[voteTypeKey1]?-1:0
                }
                return 0
            })
            //Sort on signed date with voteOp1 & voteOp2
            rows.sort((first, second) => {
                let dateKey = "documents.0.signatures.0.date"
                let voteTypeKey1 = "documents.0.template.userData.voteType.voteOp1"
                let voteTypeKey2 = "documents.0.template.userData.voteType.voteOp2"
                if(first[voteTypeKey1]!== undefined && second[voteTypeKey1]!== undefined && first[dateKey] && second[dateKey]){
                    return first[voteTypeKey1]===second[voteTypeKey1]&&first[voteTypeKey2]===second[voteTypeKey2]?(moment(first[dateKey])-moment(second[dateKey])):0 
                }
                return 0
            })
            rows.forEach((row) =>{
                let rowVal = []
                configKeys.columns.forEach((col) => {
                    if(col.type === "question"){
                        questionHeaders.forEach((ques, i) =>{
                            if(rowIndex == 0){
                                header.push(ques.actual)
                                secondHeader.push(ques.system)
                            }
                            rowVal.push(row[ques.system] || "")
                        })
                    }else if(notEmptyCols.includes(col.key) || col.fixed){
                        if(rowIndex == 0){
                          let head = col.key.substring(col.key.lastIndexOf(".") + 1)
                          let headName = colNames[head]?colNames[head]:head
                            header.push(headName)
                            secondHeader.push("")
                        }
                        rowVal.push(row[col.key])
                    }
                })
                if(rowIndex == 0){
                    csv = header.map((el) => `"${el}"`).join(",")
                    csv += "\n"
                }
                csv += rowVal.map((el) => `"${el}"`).join(",")
                csv += "\n"
                rowIndex++
            })
            questionHeaders = []
            return  csv
          },
        // addQuestionHeader(questionObj, descriptionData){
        //     let keys = Object.keys(questionObj)
        //     let lang = "sv_SE"

        //     if(keys.length < 1 ){
        //         console.warn("No translation key found")
        //         return
        //     }
        //     if(keys.length > 1 ){
        //         console.warn("Multiple translation keys found")
        //     }
        //     if(!keys.includes(lang)){
        //         lang = keys[0]
        //     }
        //     let vals = questionObj[lang]
        //     if(vals){
        //         let lastQNo = ""
        //         //TODO: Error handling
        //         vals.forEach((val, i) =>{
        //             let qNo;
        //             let systemNo = `question${i+1}Ans`
        //             if(val.ques.trim() === ""){
        //                 //Take from descriotion
        //                 let desc = descriptionData[lang][i]
        //                 qNo = `${lastQNo} ${desc.split(/\s\p{Lu}/u)[0]}`
        //             }else{
        //                 qNo = val.ques.split(/\s\p{Lu}/u)[0]
        //                 lastQNo = qNo
        //             }

        //             let exists = this.questionHeaders.some((el) => el.system === systemNo && el.actual === qNo )
        //             if(!exists){
        //                 this.questionHeaders.push({
        //                     system: systemNo,
        //                     actual: qNo,
        //                     index: i
        //                 })
        //             }

        //         })

        //     }else{
        //         console.warn("Cannot find the questions")
        //     }
        // },
        downloadCSV(){
            var blob = new Blob([this.csv], {type: "text/csv;charset=utf-8"});
            saveAs(blob, `${this.selectedTag}.csv`);
        },
        steps() {
            return [
                { title: this.__('step1') },
                { title: this.__('step2'), loading: true},
            ]
        },

        validateForm(form) {
            return this.$refs[form].validate()
        },

        nextStep() {
            if (!this.validateForm(`form${this.step}`)) {
                return
            }

            if (this.step == 1) {
                this.getEnvelopes()
            }


            this.step++

        },

        previousStep(n) {
            this.step = n
        },


    },
    async created() {
        console.log("Dashboard created")
        // Get sheets data
        try {
            const [translations] = await Promise.all([
                axios.get('https://sheets.web-services.verified.eu/postrosta-report/translations')
            ])
            this.translations = translations.data
            this.mergeLocale(translations.data);

        } catch (e) {
            console.log(e)
            this.exceptions.push(new Error("Unable to get sheet data. Try refreshing your browser."))
        }

        this.loading = false
    }

};