import axios from 'axios'
import { auth } from '@verified/libvf'
import "./login.scss"

export default {
    name: "ve-login",
    props: {
        logosrc: {
            type: String
        },
        logowidth: {
            type: String
        }
    },
    data() {
        return {
            auth: {},
            loggedIn: false,
            translations: null,
            noMatch: false,
            exceptions: [],
            loading: true,
            busy: false,
            user: {}
        }
    },
    watch: {
        exceptions: function(newVal) {
            if(newVal.length)
                this.$emit('exception', newVal[newVal.length - 1])
        }
    },
    methods: {
        // Return translation for given key
        __(key) {
            if(this.translations && this.translations[key])
                return this.translations[key][this.$root.locale]
            return '...'
        },
        // Authentication
        async authenticate(authenticationInProgress) {
            if(authenticationInProgress && !this.$refs['loginForm'].validate()){
                return
            }
            this.noMatch = false
            this.busy = true

            if(authenticationInProgress) {
                try {
                    this.token = await auth.login({
                        email: this.auth.email,
                        password: this.auth.password
                    })				
                    this.continueAuthentication()
                    this.loading = true
                } catch(e) {
                    console.log(e)
                    this.exceptions.push(new Error("An error occured during call to authenticate"))
                    this.busy = false
                    this.loading = false
                    this.noMatch = true
                    this.auth.password = undefined
                }
            } else {
                if(localStorage.getItem("user")) {
                    this.user = JSON.parse(localStorage.getItem("user"))
                    this.token = this.user.token
                    this.continueAuthentication()
                } else {
                    this.busy = false
                    this.loading = false
                }
            }
        },
        async continueAuthentication() {
            try {
                await auth.setToken(this.token)
                
                const user = await auth.getUserinfo()

                this.user = {
                    givenName: user.givenName,
                    familyName: user.familyName,
                    email: user.email,
                    token: this.token,
                }
                localStorage.setItem("user", JSON.stringify(this.user))
                
                await auth.authenticate({
                    token: auth.token,
                    namespace: this.user.namespace
                })

                this.init()

            } catch(e) {
                console.log(e)
                this.exceptions.push(new Error("An error occured getting user information"))
                localStorage.removeItem("user")
                this.busy = false
                this.loading = false
            }
        },
        init() {
            this.loggedIn = true
            this.busy = false
            this.loading = false
            setTimeout(() => {
                this.$emit('login', this.user)
            }, 500)
        },
    },
    // computed:{
    //     invalidForm() {
    //         console.log(this.$refs)
    //         return !this.$refs || !this.$refs['loginForm'] || !this.$refs['loginForm'].validate()
    //     }        
    // },
    async created() {
        
        // Get sheets data
        try {
            const [login] = await Promise.all([
                axios.get('https://sheets.web-services.verified.eu/login/translations')
            ])
            this.translations = login.data
        } catch(e) {
            this.exceptions.push(new Error("Unable to get sheet data. Try refreshing your browser."))
        }
                    
        this.authenticate(false)
    }
}