import axios from 'axios'
import Vue from 'vue'
import components from '../components'

export default {
    name: 'App',
    components: {
    },
	data() {
		return {
			qp: [],
			translations: {},
			configuration: {},
			tags: [],
			loading: true,
            exceptions: [],
            isLoggedIn: false,
			user: {},
			ud: {
				company: {}
			}
		}
	},
	methods: {
		// Return translation for given key
		__(key) {
			if(this.translations[key])
				return this.translations[key][this.$root.locale]
		},
		// Return config label for given key and language, or value if no label
		__config(key) {
			if(this.configuration[key])
				if(this.configuration[key][this.$root.locale])
					return this.configuration[key][this.$root.locale]
				else
					return this.configuration[key].value
		},
		colorLuminance(hex, lum) {
			// Validate hex string
			hex = String(hex).replace(/[^0-9a-f]/gi, '')
			if(hex.length < 6)
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			lum = lum || 0;
			
			// Convert to decimal and change luminosity
			let rgb = '#', c, i
			for(i = 0; i < 3; i++) {
				c = parseInt(hex.substr(i * 2, 2), 16)
				c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
				rgb += ('00' + c).substr(c.length)
			}
			return rgb
		},
		formSelected(value) {
		},
		setLanguage(lang) {
			this.$root.locale = lang
			this.setIso(this.$root.locale)
			this.$forceUpdate()
		},
    openUrl(url) { window.open(url) },
	},
    async created() {
        console.log("App created")
        Vue.use(components)
        this.$root.locale = 'en_EN'
        this.setIso("en_EN")
        try {
            const [config, postrostaConfig, postrostConfig, postrostaV2Config] = await Promise.all([
                axios.get('https://sheets.web-services.verified.eu/postrosta-report/config'),
                axios.get('https://sheets.web-services.verified.eu/postrosta/config'),
                axios.get('https://sheets.web-services.verified.eu/postrost/config'),
                axios.get('https://sheets.web-services.verified.eu/postrosta-v2/config')
            ])
            this.configuration = config.data
            this.tags = [...new Set([...Object.keys(postrostaConfig.data.token), ...Object.keys(postrostConfig.data.token), ...Object.keys(postrostaV2Config.data.token)])]

        } catch(e) {
            console.log(e)
            this.exceptions.push(new Error("Unable to get sheet data. Try refreshing your browser."))
        }
        document.title = this.__config('browserTitle')
        this.loading = false
    }    

}